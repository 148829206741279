import Vue from 'vue'
import VueRouter from 'vue-router'
import SiteUrl from "@/libraries/SiteUrl.js";

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    beforeEnter() {
      window.location.href = SiteUrl.account('manage');
    },
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   route level code-splitting
  //   this generates a separate chunk (about.[hash].js) for this route
  //   which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // },
  {
    path: '/shop/:web_name',
    name: 'shop',
    component: () => import('../views/MyOrders.vue'),
    props: {page: 'home'},
  },
  {
    path: '/order/:web_name',
    name: 'order_list',
    component: () => import('../views/MyOrders.vue'),
    props: {page: 'order'},
  },
  {
    path: '/(inform_payment|informpayment)/:web_name',
    name: 'inform_payment',
    component: () => import('../views/InformPayment.vue'),
    props: {page: 'inform_payment'},
  },
  {
    path: '/order/:web_name/:order_id',
    name: 'order',
    component: () => import('../views/Order.vue'),
    props: route => ({type: 'order', web_name: route.params.web_name, order_id: route.params.order_id}),// beforeEnter(to, from, next) {
  },
  {
    path: '/cart/:web_name/:order_id',
    name: 'cart',
    component: () => import('../views/Order.vue'),
    props: route => ({type: 'cart', web_name: route.params.web_name, order_id: route.params.order_id}),
    beforeEnter(to, from, next) {
      // ถ้าเข้ามาจาก begin checkout redirect (return _bc = 1 มา) ให้ไปที่ checkout แทน เพราะไม่ได้ไปแก้ที่ system/application/modules/lnwshop/_controllers/events.php กลัวจะพังเรื่อง type
      if (to.query._bc == 1) {
        next({
          name: 'checkout',
          params: {
            web_name: to.params.web_name,
            order_id: to.params.order_id,
          },
          query: {
            ...to.query,
            ...{_bc: 2}
          },
        })
      } else {
        next()
      }
    },
    // beforeEnter(to, from, next) {
    //   // ถ้าเข้า /cart มา โดยไม่ระบุ param item=1 จะให้ redirect ไป /checkout เลย
    //   if (to.params.type === 'cart' && !to.query.item) {
    //     next({
    //       name: 'checkout',
    //       params: {
    //         web_name: to.params.web_name,
    //         order_id: to.params.order_id,
    //       },
    //       // ส่งต่อ query ไปด้วย ยกเว้น item=
    //       query: {
    //         ...to.query,
    //         ...{item: undefined},
    //       },
    //     })
    //   } else {
    //     next()
    //   }
    // },
  },
  {
    path: '/checkout/:web_name/:order_id',
    name: 'checkout',
    component: () => import('../views/Order.vue'),
    props: route => ({type: 'cart', web_name: route.params.web_name, order_id: route.params.order_id}),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

// page-loading มันอยู่ที่ใน index.html ที่ไว้แสดงเวลาเข้ามาที่เว็บไปก่อน
router.afterEach(() => {
  let page_load = window.document.getElementById("page-loading");
  if (page_load) {
    page_load.remove();
  }
})

export default router